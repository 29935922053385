import React, { FunctionComponent } from "react";

import AssetNotes from "./AssetNotes";
import AssetTags from "./AssetTags";
import AssetTurnaround from "./AssetTurnaround";
import AssetDeletionButton from "./AssetDeletionButton";
import { ClientConfiguration } from "../../UploadInvitationModel";
import UploadAssetModel from "../models/UploadAssetModel";
import UploadProgess from "./AssetUploadProgressView";
// import showNotification from "../../../helpers/showNotification";
import prettySize from "../../../helpers/prettySize";

export interface AssetViewProps {
  clientConfig: ClientConfiguration;
  asset: UploadAssetModel;
  onModified: (meta: { [key: string]: any }) => void;
  remove: () => void;
  applyAll: (key: string, value: any) => void;
  isUploading?: boolean;
}

/**
 * Wrapper for displaying a single asset that the user is uploading.
 * Contains various components that are toggled depending on the state of
 * props.clientConfig. See each one for details.
 */
const AssetView: FunctionComponent<AssetViewProps> = (props) => {
  // if (props.asset.uploadProgress?.error?.code) {
  //   const errorCode = props.asset.uploadProgress.error.code;
  //   if (errorCode === "NetworkingError") {
  //     showNotification(
  //       "Network failure",
  //       props.asset.file.name + " failed to upload. Your network connection has stopped working.",
  //       "danger"
  //     );
  //   }
  // }

  const assetSize = prettySize(props.asset.file.size);

  return (
    <>
      <tr className="asset">
        <td width="25%">{props.asset.file.name} <br /><em>{assetSize}</em></td>
        <td width="30%">
          <AssetNotes {...props} />
        </td>
        {/* <td>{assetSize}</td> */}
        <td width="40%" className="asset-turnaround">
          <AssetTurnaround {...props} />
        </td>
        {/* <td>{props.asset.uploadProgress ? <UploadProgess {...props.asset.uploadProgress} /> : <p>Ready</p>}</td> */}
        <td width="5%" className="asset-delete-column">
          <AssetDeletionButton {...props} />
        </td>
      </tr>
      {(props.clientConfig.primary_asset_tags && props.clientConfig.showTags) && <AssetTags {...props} />}
      {props.asset.uploadProgress && <tr className="asset-progress-row"><td colSpan={4}><UploadProgess {...props.asset.uploadProgress} /></td></tr>}

    </>
  );
};

export default AssetView;
