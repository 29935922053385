import React, { FunctionComponent, useState, Fragment, useEffect } from 'react';
import { useParams, Redirect } from "react-router-dom";

import UploadInvitationModel from "../UploadInvitationModel";
import UploadSessionModel from "../UploadSessionModel";

interface T1CAuthBridgeProps {
  uploadSession: UploadSessionModel;
  setUploadSession: (as: UploadSessionModel) => void;
}

/**
 * Allows a T1 Cloud user to access an upload session from a one-time link
 */
const T1CAuthBridge: FunctionComponent<T1CAuthBridgeProps> = (props) => {

  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const token = useParams<string>();

  useEffect(() => {
    const secret = document.cookie.split(";").map(cookie => {
      return cookie.split("=");
    }).filter(cookie => {
      return cookie[0].trim() === "secret" + token.token.substr(0, 4)
    });

    if (!secret.length) {
      setError("Invalid request");
    }

    if (!isWorking) { //mutex
      UploadInvitationModel.claim(token.token, secret[0][1]).then(
        (invitation: UploadInvitationModel) => {
          setIsWorking(true);
          props.setUploadSession({
            isAuthenticated: true,
            invitation: invitation,
            token: token.token
          });
        }).catch(e => {
          console.error(e);
          setError(e);
        });
    }
  }, [error, isWorking, props, token])

  return (
    <Fragment>
      {props.uploadSession.isAuthenticated ? <Redirect to='/upload' /> : (
        error !== null ? <p>{error}</p> : <div className="uploader-transition"><div className="loader">Loading...</div> <p className="uploader-transition-text">Loading Uploader</p></div>
      )
      }
    </Fragment>
  )
}

export default T1CAuthBridge;
