import { MDBProgress } from "mdbreact";
import React, { Fragment, FunctionComponent } from "react";

export interface UploadProgressProps {
  percent: number;
  complete?: boolean;
  error?: {
    code?: string;
    hostname?: string;
    message?: string;
    region?: string;
    retryable?: boolean;
    time?: void;
  };
  retries?: number;
}

/**
 * Displays a readonly view of upload progress information.
 */
const UploaderProgress: FunctionComponent<UploadProgressProps> = (props) => {
  // const roundedProgress = props.percent.toFixed(0)

  let progressBarColor = 'success'
  props.error && (progressBarColor = 'danger')

  let retryContent
  if (props.retries) {
    if (props.retries > 0) {
      retryContent = <p className="text-right">
        <small className="orange-text">Retry {props.retries}</small>
      </p>
    }
  }

  return (
    <Fragment>
      {/* {!props.error ? ( */}
      <MDBProgress material color={progressBarColor} value={props.percent} height="10px" className="m-0"></MDBProgress>
      {/* ) : null} */}
      {props.complete && (
        <p className="text-right">
          <small className="green-text">Complete</small>
        </p>
      )}

      {/* {props.error ? <p className="red-text">Error: {props.error.message}</p> : null}
      {props.retries ? <p className="orange-text">Retry #: {props.retries}</p> : null} */}

      {props.error && (
        <p className="text-right">
          <small className="red-text">Failed: {props.error.message}</small>
        </p>
      )}
      {retryContent}
    </Fragment>
  );
};

export default UploaderProgress;
