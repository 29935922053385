import { MDBBtn, MDBCol, MDBRow } from "mdbreact";
import React, { FunctionComponent, useEffect } from "react";

import { AssetViewProps } from "./AssetView";

/**
 * Displays a selector for displaying turnaround options.
 * This is dependant on the property props.clientConfig.showTurnaround and the
 * list of possible values in props.clientConfig.turnaroundOptions
 */
const AssetTurnaround: FunctionComponent<AssetViewProps> = (props: AssetViewProps) => {
  const options = props.clientConfig.turnaroundOptions ? props.clientConfig.turnaroundOptions : [];
  const selected = props.asset.meta["turnaround"];

  useEffect(() => {
    // this is required to set a default value for meta["turnaround"]
    // handleChange is no fired unless the user interacts with the element,
    // so we need this to preserve the 'default' choice if left so
    props.asset.meta["turnaround"] = props.clientConfig.t1cDefaultTurnaround;
    props.onModified(props.asset.meta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // empty list means will only happen on first render

  const handleChange = (event) => {
    props.asset.meta["turnaround"] = event.target.value;
    props.onModified(props.asset.meta);
  };

  if (!props.clientConfig.showTurnaround) {
    return null;
  }
  const selectId = "turnaround-input-" + props.asset.file.name;

  return (
    <MDBRow>
      <MDBCol size="8">
        <div className="form-group">
          <label htmlFor={selectId}>Requested turnaround</label>
          <select
            id={selectId}
            className="form-control"
            onChange={(e) => handleChange(e)}
            value={selected}
            disabled={props.isUploading || props.asset.uploadProgress?.complete}
          >
            {options.map((o, i) => {
              return (
                <option key={i} value={o}>
                  {o}
                </option>
              );
            })}
          </select>
        </div>
      </MDBCol>
      <MDBCol size="4">
        <MDBBtn
          onClick={(e) => props.applyAll("turnaround", selected)}
          color="default"
          className="apply-to-all turnaround-apply-to-all"
          size="sm"
          disabled={props.isUploading || props.asset.uploadProgress?.complete}
        >
          Apply to all
          </MDBBtn>

      </MDBCol>
    </MDBRow>
  );
};

export default AssetTurnaround;
