import {useState, useRef} from 'react';

// wraps React.useState into a function that returns
// [state, setter, getter]
// see discussion at https://github.com/facebook/react/issues/14543#issuecomment-461422905
export function useStateWithGetter<T>(initial): [T, (T)=>void, ()=>T] {
  const ref = useRef();
  const [state, setState] = useState(initial);
  ref.current = state;
  const set = (value) => {
    ref.current = value;
    setState(value);
  };
  const get = () => {
    const res = ref.current as unknown;
    return res as T;
  };
  return [state, set, get];
}
