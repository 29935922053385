import React, {useState, FunctionComponent} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import PasscodePrompt from "./routes/invitation/views/PasscodePromptView";
import UploaderView from "./routes/upload/views/UploaderView";
import UploadSessionModel from "./routes/UploadSessionModel";
import NotFound from "./routes/404/404NotFound";
import PrivateRoute from "./routes/invitation/views/PrivateRoute";
import T1CAuthBridge from "./routes/T1CBridge/T1CAuthBridge";
import T1ConsoleAuthBridge from "./routes/T1CBridge/T1ConsoleAuthBridge";

/**
 * The app presents three routes:
 * "/invitation/:token" is given to a user as a single-user invitation and
 * performs validation against a 2nd factor token provided by SMS or dictation.
 * /upload" presents the uploader interface, and is only available to an
 * app instance which has gained a valid UploadSession object (either from then
 * aforementioned url or otherwise).
 * "/" is the site route, and displays a 404 by default.
 */
const App:FunctionComponent = () => {

  const [uploadSession, setUploadSession] = useState<UploadSessionModel>(
    new UploadSessionModel({
    isAuthenticated: false,
    invitation: null,
    token: null
  }));

  return (
    <Router>
      <div>
      <ReactNotification />
        <Switch>

        <Route
            path="/invitation/t1cbridge/:token"
            children={
              <T1CAuthBridge
                uploadSession={uploadSession}
                setUploadSession={setUploadSession}/>} />
        <Route
            path="/invitation/t1consolebridge/:token/:passcode"
            children={
              <T1ConsoleAuthBridge
                uploadSession={uploadSession}
                setUploadSession={setUploadSession}/>} />

          <Route
            path="/invitation/:token"
            children={
              <PasscodePrompt
                uploadSession={uploadSession}
                setUploadSession={setUploadSession}/>} />

          <PrivateRoute
            uploadSession={uploadSession}
            path="/upload"
            children={<UploaderView {...uploadSession}/>}
            onFailRedirectTo="/" />

          <Route
            path="/"
            children={<NotFound/>} />

        </Switch>
      </div>
    </Router>
  );
}

export default App;
