import React, { Fragment, FunctionComponent, useState} from "react";
import { MDBAlert, MDBBtn, MDBCard, MDBCardBody, MDBTable, MDBTableBody, MDBContainer,
 MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from "mdbreact";

import UploadSessionModel from "../../UploadSessionModel";
import UploadEventModel from "../models/UploadEventModel";
import showNotification from "../../../helpers/showNotification";

const CustomerDetailsCapture: FunctionComponent<UploadSessionModel> = (props) => {

  // these are optional, only captured if the modal is displayed (T1-398)
  const [name, setName] = useState<string|null>(null)
  const [email, setEmail] = useState<string|null>(null)
  const [companyName, setCompany] = useState<string|null>(null)
  const [projectName, setProjectName] = useState<string|null>(null)
  const [modalVisible, setModalVisible] = useState<boolean>(true)
  const [isWorking, setIsWorking] = useState<boolean>(false)

  const submitClientDetails = (e) => {
    setIsWorking(true);

    if (props.invitation) {
      new UploadEventModel({
        messageType: "CUSTOMER_DETAILS_CAPTURED",
        uploadSessionToken: props.token,
        meta: {
          customerName : name,
          customerEmail: email,
          companyName: companyName,
          projectName: projectName
        },
      }).send(props.invitation).then(() => {
        setIsWorking(false);
        setModalVisible(false);
      }).catch((err) => {
        showNotification('Error', 'Unable to update your details', 'danger')
      });
    }
  }

  if (props.invitation == null) {
    return null;
  }

  return (
    <Fragment>
      <MDBModal isOpen={props.invitation.clientConfig.displayCaptureModal && modalVisible}>
        <MDBModalHeader >Details Required</MDBModalHeader>
        <MDBModalBody>
          <form onSubmit={(e) => submitClientDetails(e)}>
            <MDBInput
              name="Name"
              getValue={(e) => setName(e.toString())}
              label="Please enter your name"
            />
            <MDBInput
              name="Email Address"
              getValue={(e) => setEmail(e.toString())}
              label="Please enter your email address"
            />
            <MDBInput
              name="Company Name"
              getValue={(e) => setCompany(e.toString())}
              label="Please enter your company name"
            />
            <MDBInput
              name="Project Name"
              getValue={(e) => setProjectName(e.toString())}
              label="Please enter your project name"
            />
          </form>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn type="submit"
              color="default"
              disabled={new Set([name, email, companyName, projectName]).has(null)}
              onClick={(e) => submitClientDetails(e)}>
              OK
          </MDBBtn>

          {isWorking ?
           <div className="spinner-border" role="status">
             <span className="sr-only">Loading...</span>
           </div>
           : null}

        </MDBModalFooter>
      </MDBModal>
    </Fragment>
  )
}

export default CustomerDetailsCapture;
