import React, { FunctionComponent, useEffect } from "react";

import AssetNotes from "./AssetNotes";
import SupportingAssetTags from "./SupportingAssetTags";
import AssetDeletionButton from "./AssetDeletionButton";
import { ClientConfiguration } from "../../UploadInvitationModel";
import UploadSupportingAssetModel from "../models/UploadSupportingAssetModel";
import UploadProgess from "./AssetUploadProgressView";
import showNotification from "../../../helpers/showNotification";
import prettySize from "../../../helpers/prettySize";
// import { isPropertySignature } from "typescript";
import { MDBBtn, MDBCol, MDBRow } from "mdbreact";

export interface SupportingAssetViewProps {
  clientConfig: ClientConfiguration;
  asset: UploadSupportingAssetModel;
  onModified: (meta: { [key: string]: any }) => void;
  remove: () => void;
  applyAll: (key: string, value: any) => void;
  allAssets: any;
  isUploading?: boolean;
}

/**
 * Wrapper for displaying a single asset that the user is uploading.
 * Contains various components that are toggled depending on the state of
 * props.clientConfig. See each one for details.
 */
const SupportingAssetView: FunctionComponent<SupportingAssetViewProps> = (props) => {
  useEffect(() => {
    // this is required to set a default value for meta["turnaround"]
    // handleChange is no fired unless the user interacts with the element,
    // so we need this to preserve the 'default' choice if left so
    props.asset.meta["supportingAsset"] = true;
    props.asset.meta["supportingAssetFor"] = 'blank';
    props.onModified(props.asset.meta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // empty list means will only happen on first render

  if (props.asset.uploadProgress?.error?.code) {
    const errorCode = props.asset.uploadProgress.error.code;
    if (errorCode === "NetworkingError") {
      showNotification(
        "Network failure",
        props.asset.file.name + " failed to upload. Your network connection has stopped working.",
        "danger"
      );
    }
  }

  const assetSize = prettySize(props.asset.file.size);

  const selectId = "supporting-asset-for-input-" + props.asset.file.name;

  const handleChange = (e) => {
    let newValue = e.target.value
    props.asset.meta["supportingAssetFor"] = newValue;
    props.onModified(props.asset.meta);
  };

  return (
    <>
      <tr className="asset">
        <td width="25%">{props.asset.file.name}<br /><em>{assetSize}</em></td>
        <td width="30%">
          <AssetNotes {...props} />
        </td>
        <td width="40%">
          <MDBRow>
            <MDBCol size="8">
              <div className="form-group">
                <label htmlFor={selectId}>Supporting asset for...</label>
                <select
                  id={selectId}
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                  value={props.asset.meta.supportingAssetFor}
                  disabled={props.isUploading || props.asset.uploadProgress?.complete}
                >
                  <option value="blank"></option>
                  <option value="0">Global to this project</option>
                  {props.allAssets.map((allAsset) => {
                    return (
                      <option key={allAsset.file.name} value={allAsset.file.name}>
                        {allAsset.file.name}
                      </option>
                    );
                  })}
                  <option value="other">Another asset not in this list</option>
                </select>
              </div>

            </MDBCol>
            <MDBCol size="4">
              <MDBBtn
                onClick={(e) => props.applyAll("supportingAssetFor", props.asset.meta.supportingAssetFor)}
                color="default"
                className="apply-to-all turnaround-apply-to-all"
                size="sm"
                disabled={props.isUploading || props.asset.uploadProgress?.complete}
              >
                Apply to all
              </MDBBtn>

            </MDBCol>
          </MDBRow>
        </td>
        <td width="5%" className="asset-delete-column">
          <AssetDeletionButton {...props} />
        </td>
      </tr>
      {props.clientConfig.supporting_asset_tags && <SupportingAssetTags {...props} />}
      {props.asset.uploadProgress && <tr className="asset-progress-row"><td colSpan={4}><UploadProgess {...props.asset.uploadProgress} /></td></tr>}
    </>
  );
};

export default SupportingAssetView;
