import UploadInvitationModel from "../../UploadInvitationModel";
import {escapeUnicode} from "../../../helpers/strEscape";
var https = require('https');

/**
 * Message used for providing upload event notifications to the API
 */
export default class UploadEventModel {
  messageType: "UPLOAD_SESSION_STARTED"|"UPLOAD_SESSION_COMPLETED"|
                "ASSET_UPLOAD_STARTING"| "ASSET_UPLOAD_COMPLETED"|
                "ASSET_UPLOAD_ERROR"|
                "ASSET_READ_ERROR"| "UPLOAD_SESSION_ABORTED"|
                "CUSTOMER_DETAILS_CAPTURED";
  uploadSessionToken: string;
  meta: { [key:string]:any; } // the meta information is the filename/size
  // in this context. For the actual user-submitted meta data, see AssetUserMetaDataModel.tsx

  public constructor({
      messageType,
      uploadSessionToken,
      meta}) {
    this.messageType = messageType;
    this.uploadSessionToken = uploadSessionToken;
    this.meta = meta;
  }

  /**
   * Send the upload event notification. The response is ignored.
   * An UploadInvitationModel instance is required for authentication,
   * if this is null, the message will not be sent.
   */
  public async send(invitation: UploadInvitationModel | null): Promise<void> {
    if(invitation == null ){
      return;
    }
    const payload = JSON.stringify(this);
    const payloadEsc = escapeUnicode(payload);
    const opts = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': payloadEsc.length
      },
      body: payloadEsc
    }
    const signedRequest = invitation.signRequest(opts, "/logUploadEvent/", "POST");

    function request(opts) {
        https.request(opts, function(res) {
            //res.pipe(process.stdout)
          }).end(opts.body || '')
    }
    return request(signedRequest);
  }
}
