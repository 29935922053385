/*
To do: Supporting assets is only half done!
Currently we separate assets into assets, which are primary assets (videos and audio), and supportingAssets (everything else).
Supporting assets have their own class separate from primary assets, as they don't have a turnaround and instead have a "which asset does this link to" field
Currently only primary assets are uploaded - We still need to make it so supporting assets are uploaded, and that we somehow send data about the supporting assets (which asset each supports etc)

Nick has put this comment on Jira which outlines how part of this should work:
https://take1jira.atlassian.net/browse/CI-6026?focusedCommentId=99034

*/

import React, { FunctionComponent, Fragment, useState, useCallback, useEffect, useRef } from "react";
import { useStateWithGetter } from "../../../utils";
import Dropzone from "react-dropzone";
import Layout from "../../../layout/Layout";
import { MDBAlert, MDBBtn, MDBCard, MDBCardBody, MDBTable, MDBTableBody, MDBContainer,
 MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from "mdbreact";
import showNotification from "../../../helpers/showNotification";

import { AssetViewProps } from "./AssetView";
import { SupportingAssetViewProps } from "./SupportingAssetView";
import UploadInvitationModel from "../../UploadInvitationModel";
import UploadSessionModel from "../../UploadSessionModel";
import UploadAssetModel from "../models/UploadAssetModel";
import UploadEventModel from "../models/UploadEventModel";
import AssetView from "./AssetView";
import SupportingAssetView from "./SupportingAssetView";
import S3UploadEngine from "../S3UploadEngine";
import { UploadProgressProps } from "./AssetUploadProgressView";
import CustomerDetailsCapture from "./CustomerDetailsCapture";

import ClientInstructions from "./ClientInstructions";
import UploadSupportingAssetModel from "../models/UploadSupportingAssetModel";

/**
 * Presents a view that allows a user to select files to upload via drag-and-drop,
 * and define their upload properties as per uploadSession.invitation.clientConfig.
 *
 * Once upload is initiated, display the relevent upload progress of each time.
 */
const UploaderView: FunctionComponent<UploadSessionModel> = (props) => {

  if (props.invitation) {

    props.invitation.clientConfig.primary_asset_tags = [
      'B-Roll',
      'Interview',
      'Reality',
      'Timelapse'
    ]
    props.invitation.clientConfig.supporting_asset_tags = [
      'Facesheet'
    ]
  }

  const [assetsUploading, setAssetsUploading] = useState(false)
  const [supportingAssetsUploading, setSupportingAssetsUploading] = useState(false)
  const [assetsComplete, setAssetsComplete] = useState(false)
  const [supportingAssetsComplete, setSupportingAssetsComplete] = useState(false)

  // we need a getter for the Asset list, see updateUploadProgress below:
  const [assets, setAssets, getAssets] = useStateWithGetter<UploadAssetModel[]>([]);
  const [supportingAssets, setSupportingAssets, getSupportingAssets] = useStateWithGetter<UploadAssetModel[]>([]);

  const allComplete = useRef(false)

  useEffect(() => {
    if (allComplete.current) {
      new UploadEventModel({
        messageType: "UPLOAD_SESSION_COMPLETED",
        uploadSessionToken: props.token,
        meta: {},
      }).send(props.invitation);

    }
  }, [allComplete.current])

  const sendCompleteEvent = () => {
    allComplete.current = true
  }

  const startUpload = async () => {

    let supportingAssetsValid = true
    supportingAssets.forEach(supportingAsset => {
      if (supportingAsset.meta.supportingAssetFor === 'blank') {
        supportingAssetsValid = false
      }
    })
    if (supportingAssetsValid) {

      if (assets.length) {
        setAssetsUploading(true)
        setAssetsComplete(false)
      }
      else {
        setAssetsUploading(false)
        setAssetsComplete(true)
      }
      if (supportingAssets.length) {
        setSupportingAssetsUploading(true)
        setSupportingAssetsComplete(false)
      }
      else {
        setSupportingAssetsUploading(false)
        setSupportingAssetsComplete(true)
      }

      const allAssets: UploadAssetModel[] = [
        ...assets,
        ...supportingAssets
      ]

      new UploadEventModel({
        messageType: "UPLOAD_SESSION_STARTED",
        uploadSessionToken: props.token,
        meta: {
          selectedAssets: JSON.stringify(allAssets),
        },
      }).send(props.invitation);

      await Promise.all(
        allAssets.map(async (asset) => {

          if (!asset.uploadProgress || asset.uploadProgress.error !== '') {
            if (asset.meta.hasOwnProperty("supportingAsset")
              && asset.meta["supportingAsset"]) {

              new S3UploadEngine(asset, props, updateUploadSupportingAssetProgress(asset), resetUploaderState).start();
            } else {
              new S3UploadEngine(asset, props, updateUploadProgress(asset), resetUploaderState).start();
            }
            //status notifications sent on completion / failure:

          }

        })
      ).then((data) => {
        // console.log('upload session completed')
        // new UploadEventModel({
        //   messageType: "UPLOAD_SESSION_COMPLETED",
        //   uploadSessionToken: props.token,
        //   meta: {},
        // }).send(props.invitation);
      }).catch((err) => {
        new UploadEventModel({
          messageType: "UPLOAD_SESSION_ABORTED",
          uploadSessionToken: props.token,
          meta: {},
        }).send(props.invitation);
      });

    }
    else {
      showNotification('Error', 'Please make sure you select a linking option for each supporting asset.', 'danger')
    }

  };

  const resetUploaderState = () => {
    setAssetsUploading(false)
    setSupportingAssetsUploading(false)
    setAssetsComplete(false)
    setSupportingAssetsComplete(false)
  }

  /*
   * New items are dragged into the drop zone. acceptedFiles are those which
   * are of allowed types (props.clientConfig.expectedFileTypes)
   */
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (props.invitation == null) {
        console.error("UploaderView.onDrop: invitation is null");
        return;
      }

      const invitation = props.invitation;

      // display oversize file warning
      const maxSizeGB = invitation.clientConfig.maxFileSizeBytes / 1073741824;
      for (let i = 0; i < acceptedFiles.length; i++) {
        if (acceptedFiles[i].size > invitation.clientConfig.maxFileSizeBytes) {
          const sizeGB = acceptedFiles[i].size / 1073741824;
          const msg =
            'Your file "' +
            acceptedFiles[i].name +
            '" is ' +
            sizeGB.toFixed(2) +
            "GB (our advisory specification is 300MB per 30mins). " +
            "Please compress your media file/s or contact our team on: " +
            "UK-0800 0854 418/ US-855 958 2531 production@take1.tv for further info.";
          showNotification("File Size Limitation " + maxSizeGB + "GB", msg, "danger");
        }
      }

      // filter oversize files from the list
      acceptedFiles = acceptedFiles.filter((f) => {
        return f.size <= invitation.clientConfig.maxFileSizeBytes;
      });

      // check they are not already added
      const files = assets.map((a: UploadAssetModel) => {
        return a.file;
      });

      const newfiles = acceptedFiles.filter((f) => {
        //return files.indexOf(f) === -1;
        return (
          files
            .map((file) => {
              return file.name;
            })
            .indexOf(f.name) === -1
        );
      });

      // raise raw file objects the type of UploadAsset and add to the assets
      // state property
      let newAssets: UploadAssetModel[] = [];
      let newSupportingAssets: UploadSupportingAssetModel[] = [];
      newfiles.forEach((file) => {
        if (file.type.includes("video") || file.type.includes("audio") || file.type.includes('zip')) {
          newAssets.push({ file: file, meta: {} });
          setAssetsComplete(false)
        } else {
          newSupportingAssets.push({ file: file, meta: {} });
          setSupportingAssetsComplete(false)
        }
      });
      const updatedAssets = assets.concat(newAssets);
      setAssets(updatedAssets);
      const updatedSupportingAssets = supportingAssets.concat(newSupportingAssets);
      setSupportingAssets(updatedSupportingAssets);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assets, setAssets, supportingAssets, setSupportingAssets]
  );

  /**
   * callback passed to child components to signal removal of an asset from
   * the list.
   */
  const removeAsset = (ua: UploadAssetModel) => {
    return (): void => {
      // we need to shallow copy the original array
      // otherwise we get unexpected results
      const items = [...assets];
      const i = items.indexOf(ua);
      items.splice(i, 1);
      setAssets(items);
    };
  };

  const removeSupportingAsset = (ua: UploadSupportingAssetModel) => {
    return (): void => {
      // we need to shallow copy the original array
      // otherwise we get unexpected results
      const items = [...supportingAssets];
      const i = items.indexOf(ua);
      items.splice(i, 1);
      setSupportingAssets(items);
    };
  };

  /**
   * callback passed to child components to update the metadata to be associated
   * with a given asset.
   */
  const onModified = (original: UploadAssetModel) => {
    return (newMeta: { [key: string]: any }): void => {
      // once again, shallow copy the items and the instance
      // we are planning to mutate
      const items = [...assets];
      const i = items.indexOf(original);
      const item = { ...items[i] };
      item.meta = newMeta;
      items[i] = item;
      original = item;
      setAssets(items);
    };
  };

  /**
   * callback passed into the upload engine to enable updating of Upload
   * progress information.
   */
  const updateUploadProgress = (original: UploadAssetModel) => {
    return (progress: UploadProgressProps) => {
      // in this case, we need to use a getter function to the assets List
      // otherwise assets is recreated each time the component re-renders
      // and we loose the upload progress state of each item.

      // once again, shallow copy the items and the instance
      // we are planning to mutate
      const items = [...getAssets()]; //getAssets();

      // search is based upon the file attribute. Equality of 'items' would fail
      let searchIndex = -1;
      for (var i = 0; i < items.length; i++) {
        if (items[i].file === original.file) {
          searchIndex = i;
          break;
        }
      }
      const item = { ...items[searchIndex] };
      item.uploadProgress = progress;
      items[i] = item;

      // eslint-disable-next-line
      let inCompleteItems = new Array();
      items.forEach((itemInProgress) => {
        if (!itemInProgress.uploadProgress?.complete) {
          inCompleteItems.push(itemInProgress);
        }
      });
      if (!inCompleteItems.length) {
        setAssetsComplete(true);
        setAssetsUploading(false)
      }
      setAssets(items);
    };
  };
  const updateUploadSupportingAssetProgress = (original: UploadSupportingAssetModel) => {
    return (progress: UploadProgressProps) => {
      // in this case, we need to use a getter function to the assets List
      // otherwise assets is recreated each time the component re-renders
      // and we loose the upload progress state of each item.

      // once again, shallow copy the items and the instance
      // we are planning to mutate
      const items = [...getSupportingAssets()]; //getAssets();

      // search is based upon the file attribute. Equality of 'items' would fail
      let searchIndex = -1;
      for (var i = 0; i < items.length; i++) {
        if (items[i].file === original.file) {
          searchIndex = i;
          break;
        }
      }
      const item = { ...items[searchIndex] };
      item.uploadProgress = progress;
      items[i] = item;

      // eslint-disable-next-line
      let inCompleteItems = new Array();
      items.forEach((itemInProgress) => {
        if (!itemInProgress.uploadProgress?.complete) {
          inCompleteItems.push(itemInProgress);
        }
      });
      if (!inCompleteItems.length) {
        setSupportingAssetsComplete(true);
        setSupportingAssetsUploading(false)
      }
      setSupportingAssets(items);
    };
  };

  /**
   * callback passed to child components to enable setting a common
   * key/value tag against each asset in the upload list
   */
  const applyAll = (key: string, value: any): void => {
    let items = assets.map((asset) => {
      if (!asset.uploadProgress?.complete) {
        asset.meta[key] = value;
      }
      return asset;
    });
    setAssets(items);
  };
  const applyAllSupportingAssets = (key: string, value: any): void => {
    let items = supportingAssets.map((supportingAsset) => {
      if (!supportingAsset.uploadProgress?.complete) {
        supportingAsset.meta[key] = value;
      }
      return supportingAsset;
    });
    setSupportingAssets(items);
  };

  const makeProps = (a: UploadAssetModel, i: UploadInvitationModel): AssetViewProps => {
    return {
      clientConfig: i.clientConfig,
      asset: a,
      onModified: onModified(a),
      remove: removeAsset(a),
      applyAll: applyAll,
      isUploading: assetsUploading,
    };
  };
  const makeSupportingAssetProps = (
    a: UploadSupportingAssetModel,
    i: UploadInvitationModel,
    assets
  ): SupportingAssetViewProps => {
    return {
      clientConfig: i.clientConfig,
      asset: a,
      onModified: onModified(a),
      remove: removeSupportingAsset(a),
      applyAll: applyAllSupportingAssets,
      allAssets: assets,
      isUploading: supportingAssetsUploading,
    };
  };

  if (!props.invitation) {
    // this is just to satisfy the compiler...
    return null;
  }
  const invitation = props.invitation;

  let assetsCountContent;
  if (assets.length > 0 || supportingAssets.length > 0) {
    if (!assetsComplete || !supportingAssetsComplete) {

      let assetsCount = 0
      assets.forEach(asset => {
        if (!asset.uploadProgress?.complete) assetsCount++
      })
      supportingAssets.forEach(supportingAsset => {
        if (!supportingAsset.uploadProgress?.complete) assetsCount++
      })

      assetsCountContent = (
        <MDBBtn disabled className="mb-4 float-right assets-count">
          {assetsCount} file{assetsCount > 1 && "s"} {(assetsUploading || supportingAssetsUploading) ? 'uploading' : 'ready to upload'}
        </MDBBtn>
      );
    }
    else {
      assetsCountContent = null
    }
  }

  let navWarning;
  if (assetsUploading || supportingAssetsUploading) {
    navWarning = (
      <MDBAlert color="warning" className="nav-warning">
        Upload will fail if you refresh/close/navigate away from this page. Please wait until all assets have been
        uploaded successfully.
      </MDBAlert>
    );
  }

  let completeMessage
  if (assetsComplete && supportingAssetsComplete) {
    completeMessage = <MDBAlert color="success" className="nav-warning">
      <h2 className="alert-heading h5">Upload complete</h2>
      <p>All files have uploaded successfully. You can now close this window.</p>
    </MDBAlert>
    sendCompleteEvent()
  }

  const body = (
    <Fragment>
      <CustomerDetailsCapture {...props}/>
      <MDBCard className="mb-4">
        <MDBCardBody>
          <header className="page-title-container">
            <h1 className="page-title h4 d-inline-block">Take 1 Uploader</h1>
          </header>

          {navWarning}
          {completeMessage}

          <ClientInstructions invitation={invitation} />

          {process.env.REACT_APP_AWS_ENV == "production-uncapped"? null :
            <p className="mb-4">
              Please follow our{" "}
              <a href="https://www.take1.tv/support/technical/" target="_blank" rel="noopener noreferrer">
                Technical Guidelines
              </a>{" "}
              when sending us digital media.
            </p>
          }

          <fieldset disabled={assetsUploading || supportingAssetsUploading}>
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div className="uploader-assets">
                    {assets.length || supportingAssets.length ? (
                      <MDBTable>
                        <MDBTableBody>
                          {assets.length ? (
                            <>
                              <tr className="assets-heading">
                                <td colSpan={4}>Primary assets</td>
                                {/* <td colSpan={6}>Primary assets</td> */}
                              </tr>
                              {assets.map((a: UploadAssetModel, i) => {
                                return <AssetView key={i} {...makeProps(a, invitation)} />;
                              })}
                            </>
                          ) : null}
                          {supportingAssets.length ? (
                            <>
                              <tr className="assets-heading">
                                <td colSpan={4}>Supporting assets</td>
                                {/* <td colSpan={6}>Supporting assets</td> */}
                              </tr>
                              {supportingAssets.map((a: UploadSupportingAssetModel, i) => {
                                return (
                                  <SupportingAssetView key={i} {...makeSupportingAssetProps(a, invitation, assets)} />
                                );
                              })}
                            </>
                          ) : null}
                        </MDBTableBody>
                      </MDBTable>
                    ) : null}
                  </div>
                  {!assetsComplete && !supportingAssetsComplete && (<div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="dropzone-drag-target mb-4 p-4">
                      <p>Drag and drop your files here, or click to browse your computer.</p>
                    </div>
                  </div>)}

                </section>
              )}
            </Dropzone>
          </fieldset>

          {navWarning}
          {completeMessage}

          {!assetsComplete && !supportingAssetsComplete && (<MDBBtn
            color="default"
            disabled={(!assets.length && !supportingAssets.length) || assetsUploading || supportingAssetsUploading}
            onClick={(e) => startUpload()}
            className="mb-4 float-right"
          >
            Upload
          </MDBBtn>)}


          {assetsCountContent}
        </MDBCardBody>
      </MDBCard>
      <p className="text-right">&copy; Take 1 Script Services Ltd.</p>
    </Fragment>
  );

  return <Layout body={body} loggedIn={invitation} />;
};

export default UploaderView;
